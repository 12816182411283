import { Fragment } from "react";
import { Footer } from "../ReactComponents/components/Footer";
import NavbarLeft from "../ReactComponents/components/navbar_left";
import NavbarTop from "../ReactComponents/components/navbar_top";
import PortfolioContent from "../ReactComponents/container/portfolioContent";
interface props {
  // children: JSX.Element;
  content?: JSX.Element;
}
export default function PortfolioLayout(props: props) {
  return (
    <div id="portfolio-root">
      <NavbarLeft />
      <PortfolioContent>
        <Fragment>
          <NavbarTop />
          {/* {props.children} */}
          {props.content}
          <Footer/>
        </Fragment>
      </PortfolioContent>
    </div>
  );
}
