import { url } from "inspector";
import { useContext, useEffect, useRef, useState } from "react";
import { globalContext } from "../..";

interface portraitResp {
  url: string;
  caption: string[];
}

const apiUrl = "portfolio/get_portraits";
const ImageSlider = () => {
  const host = useContext(globalContext).serverUrl;
  // states
  const [portraitsInfo, setPortraitsInfo] = useState<portraitResp[]>([]);
  const [sliderContainerDim, setSliderContainerDim] = useState<number[]>([]);
  const [currentImgInd, setCurrentImgInd] = useState<number>(0);
  // refs
  const captionRef = useRef<HTMLDivElement>(null);
  const sliderContainerRef = useRef<HTMLDivElement>(null);
  // callbacks
  const onPrevBtnClicked = () => {
    setCurrentImgInd((curInd) => {
      return curInd - 1 <= 0 ? portraitsInfo.length - 1 : curInd - 1;
    });
  };
  const onNextBtnClicked = () => {
    setCurrentImgInd((curInd) => {
      return curInd + 1 >= portraitsInfo.length ? 0 : curInd + 1;
    });
  };

  useEffect(() => {
    async function getImages() {
      const resp = await fetch(host + apiUrl);
      const portrait_imgs_resp = await resp.json();
      const portrait_infos: any[] = [];
      for (
        let index = 0;
        index < portrait_imgs_resp.portraitsInfo.length;
        index++
      ) {
        const e = portrait_imgs_resp.portraitsInfo[index];
        const imgUrl = host + e.url;
        const dataUrl = URL.createObjectURL(await (await fetch(imgUrl)).blob());
        portrait_infos.push({ url: dataUrl, caption: e.caption });
      }
      setPortraitsInfo(portrait_infos);
      setCurrentImgInd(0);
    }
    getImages();

    setSliderContainerDim([
      sliderContainerRef.current!.clientWidth,
      sliderContainerRef.current!.clientHeight,
    ]);
  }, [host]);

  return (
    <div className="image-slider" ref={sliderContainerRef}>
      <div className="image-container">
        {portraitsInfo.length > 0 ? (
          <img src={portraitsInfo[currentImgInd].url} alt="it's a imageh" />
        ) : null}
      </div>
      {/* overlays */}
      {/* arrows */}
      <div className="prevArrow" onClick={onPrevBtnClicked}>
        <i className="fa fa-arrow-left"></i>
      </div>
      <div className="nextArrow" onClick={onNextBtnClicked}>
        <i className="fa fa-arrow-right"></i>
      </div>
      {/* caption */}
      <div
        className="image-slider-caption"
        ref={captionRef}
        style={
          sliderContainerDim.length === 0
            ? {}
            : {
                width: sliderContainerDim[0] / 2,
                height: sliderContainerDim[1] / 5,
              }
        }
      >
        {portraitsInfo.length > 0
          ? portraitsInfo[currentImgInd].caption.map((c, i) => (
              <p key={`image-caption-${i}`}>{c}</p>
            ))
          : null}
      </div>
      {/* dot image selection buttons */}
      <div className="image-slider-dotButtons"></div>
    </div>
  );
};
export default ImageSlider;
