import "../../../node_modules/font-awesome/css/font-awesome.css";
interface Props {
  path: string;
  faClassName: string;
}
const SocialIcon = (props: Props) => {
  return (
    <div className="social-medias-icon">
      <a href={props.path} rel="noreferrer" target="_blank">
        <span className={props.faClassName} />
      </a>
    </div>
  );
};
export default function SocialMedias() {
  return (
    <div className="social-medias">
      {/* <SocialIcon
        {...{
          path: "https://github.com/Jinming-Zhang",
          faClassName: "fa fa-twitter",
        }}
      /> */}

      <SocialIcon
        {...{
          path: "https://github.com/Jinming-Zhang",
          faClassName: "fa fa-github",
        }}
      />

      <SocialIcon
        {...{
          path: "https://www.linkedin.com/in/jinming-zhang-1144b7153/",
          faClassName: "fa fa-linkedin",
        }}
      />

      <SocialIcon
        {...{
          path: "https://www.facebook.com/jinming.zhang.501/",
          faClassName: "fa fa-facebook",
        }}
      />
    </div>
  );
}
