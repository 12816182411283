import { Fragment } from "react";
import { NavLink } from "react-router-dom";

function NavbarTop() {
  return (
    <Fragment>
      <div className="navbar-top">
        <NavLink
          to="/"
          className={(navdata) =>
            navdata.isActive ? "link-active" : "link-inactive"
          }
        >
          <span>Home</span>
        </NavLink>
        {/* <NavLink
            to="/resume"
            className={(navdata) =>
              navdata.isActive ? "link-active" : "link-inactive"
            }
          >
            <h1> Resume</h1>{" "}
          </NavLink> */}
        <NavLink
          to="/projects"
          className={(navdata) =>
            navdata.isActive ? "link-active" : "link-inactive"
          }
        >
          <span>Projects</span>
        </NavLink>
      </div>
    </Fragment>
  );
}
export default NavbarTop;
