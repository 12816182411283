import { useContext, useEffect, useState } from "react";
import { globalContext } from "../..";
interface bibioInfo {
  url: string;
  description: string;
}
export default function Bibliography() {
  const context = useContext(globalContext);
  const hosturl = context.serverUrl;

  const [bibioInfo, setBibioInfo] = useState<bibioInfo>();
  useEffect(() => {
    async function getIcon() {
      const resp = await fetch(hosturl + "portfolio/my_portfolio_icon");
      const info = await resp.json();
      setBibioInfo(info);
    }
    getIcon();
  }, [hosturl]);
  return (
    <section id="bibliograph">
      <div className="my-name">Jinming Zhang</div>
      <div className="my-pic">
        <img src="/me.jpg" alt="cant find me..." />
      </div>
      <div className="my-bibli">
        {/* {bibioInfo?.description} */}
        <p>Passions towords games</p>
        <p>Actively learning multiple aspacts of game development.</p>
        <p>
          Currently focusing on AI/ML field in MSCS at Northeastern University
          Vancouver Campus.
        </p>
      </div>
    </section>
  );
}
