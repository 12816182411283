import { TimelineObj } from "./TimelineContainer";
import "../../../node_modules/font-awesome/css/font-awesome.css";
import { useEffect, useRef, useState } from "react";

const TimelineItem = ({
  title,
  subtitle,
  duration,
  description,
  imageUrl,
}: TimelineObj) => {
  const divRef = useRef<HTMLDivElement>(null);

  const [itemDim, setItemDim] = useState<number>();
  useEffect(() => {
    console.log(divRef!.current?.scrollHeight);
    setItemDim(divRef!.current?.scrollHeight! + 16);
  }, [description]);

  return (
    <div className="timeline-item" ref={divRef} style={{ height: itemDim }}>
      <div className="timeline-item-head">
        <span className="fa fa-circle"></span>
      </div>
      <div className="timeline-item-content">
        <div className="timeline-item-content-title">{title}</div>
        <div className="timeline-item-content-subtitle">{subtitle}</div>
        <div className="timeline-item-content-duration">{duration}</div>
        <ul>
          {description.map((e, i) => (
            <li
              className="timeline-item-content-description"
              key={`item-description-${i}`}
            >
              {e}
            </li>
          ))}
        </ul>
      </div>
      <div className="timeline-item-img">
        {imageUrl ? <img src={imageUrl} alt="show case"></img> : null}
      </div>
    </div>
  );
};
export default TimelineItem;
