import { useEffect, useState } from "react";

interface ProjectCardData {
  title: string;
  time: string;
  description: string[];
  imageUrl: string;
  projectUrl?: string;
}
const ProjectCard = (data: ProjectCardData) => {
  return (
    <div className="project-card">
      <div className="project-card-imgcontainer">
        <img src={data.imageUrl} alt="project sample" />
      </div>
      <div className="project-card-texts">
        <div className="project-card-title">
          <a href={data.projectUrl} rel="noreferrer" target="_blank">
            {data.title}
          </a>
        </div>
        <div className="project-card-time">{data.time}</div>
        <div className="project-card-description">
          <ul>
            {data.description.map((e) => (
              <li className="project-card-description-item">{e}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
const Projects = () => {
  const [cards, setCards] = useState<ProjectCardData[]>([]);

  useEffect(() => {
    setCards([
      {
        title: "Butterfly Effect",
        time: "Sept 2022",
        description: [
          "Collaborated in a team of ten (Yes, team of TeN!) in a two day off-line game jam.",
          "Ensured the game design is feasible for such short game jam by actively communicating with different people during design phase and giving feedback on approximate time needed for the features.",
          "Provided help and explained the coding decision as well as collaboration tool (git) to people with little game development experience.",
        ],
        imageUrl: "https://img.itch.zone/aW1nLzEwMzU1MzE0LmpwZw==/315x250%23c/wHEd0t.jpg",
        projectUrl: "https://wolffurry.itch.io/bcjam-batterflyeffect",
      },
      {
        title: "Inconceivable Detective Madness",
        time: "April 2022",
        description: [
          "Collaborated in a team of five from three different time zones in a one week long gamejam.",
          "Effectively communicated with all members in the team and ensured the project on the right track.",
          "Elegantly prioritized tasks in a reasonable order to deliver a descent game within limited time.",
        ],
        imageUrl: "/ProjectImages/InconceivableDetective.png",
        projectUrl: "https://wolffurry.itch.io/bw",
      },
      {
        title: "How I Loss $1000",
        time: "March 2022",
        description: [
          "Worked alone in a week long game jam.",
          "Integrate visual novel with mini game.",
          "Practiced unity time line, input system and cinemachine.",
          "First time design and building a 3D game scene, learned a lot through the process.",
        ],
        imageUrl: "/ProjectImages/hil1000.png",
        projectUrl: "https://wolffurry.itch.io/how-i-lost-1000",
      },
      {
        title: "BaddestBltchWalk",
        time: "Jan 2022",
        description: [
          "Worked alone in a week long game jam.",
          "Applied newly learned Blender skill and created most of the game assets by myself.",
          "Experienced the process of modelling a simple character in Blender and import it into Unity.",
          "Improved Rhythm game development skills.",
          "Happily delivered a game with the model of one of the most charming characters in one of my most favourite animes!",
        ],
        imageUrl: "/ProjectImages/bdbw.png",
        projectUrl: "https://wolffurry.itch.io/baddestbltchwalk",
      },
    ]);
  }, []);

  return (
    <section className="project-page">
      <h1 className="section_heading">Projects</h1>
      {cards.map((e, i) => {
        return <ProjectCard {...e} key={`project-card-${i}`} />;
      })}
    </section>
  );
};
export default Projects;
