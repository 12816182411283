import { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { globalContext } from "../..";
import Bibliography from "./bibliography";
import ContactForm from "./contact_form";
import SocialMedias from "./social_medias";

export default function NavbarLeft() {
  // update webpage tab icon
  const context = useContext(globalContext);
  const iconCount = context.iconCount;
  const rad = Math.floor(Math.random() * iconCount) + 1;

  useEffect(() => {
    const icon: HTMLLinkElement = document.querySelector("#tab-icon")!;
    icon.href = process.env.PUBLIC_URL + `/WolfIcons/wolf_icon_${rad}.png`;
  }, [rad]);

  return (
    <div id="navbar-left">
      <div className="navbar-icon">
        <NavLink to="/">
          <img src={`/WolfIcons/wolf_icon_${rad}.png`} alt="wolfy" />
        </NavLink>
      </div>
      <Bibliography />
      <SocialMedias />
      <ContactForm />
    </div>
  );
}
