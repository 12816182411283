import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import PortfolioLayout from "./Pages/PortfolioLayout";
import HomePage from "./Pages/Home";
import "./styles/main.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Projects from "./Pages/Projects";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const buildConfig = {
  local: true,
  serverUrl: "https://jinming.ca/",
  iconCount: 337,
};

export const globalContext = React.createContext(buildConfig);

root.render(
  <React.StrictMode>
    <globalContext.Provider value={buildConfig}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<PortfolioLayout content={<HomePage />} />}
          ></Route>
          {/* <Route
            path="/resume"
            element={<PortfolioLayout content={<Resume />} />}
          ></Route> */}
          <Route
            path="/projects"
            element={<PortfolioLayout content={<Projects />} />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </globalContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
