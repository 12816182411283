interface TechCard {
  title: string;
  iconUrl: string;
  description: string[];
  key?: string;
}
const TechStackCard = (props: TechCard) => {
  return (
    <div className="tech-stack-content-card">
      <div className="tech-stack-content-card-title">{props.title}</div>
      <div className="tech-stack-content-card-image-div">
        <img src={props.iconUrl} alt="icon" />
      </div>
      <div className="tech-stack-content-card-description">
        {props.description.map((d, i) => (
          <p key={`teck-card-des-${i}`}>{d}</p>
        ))}
      </div>
    </div>
  );
};
const SmallTechStackCard = (props: TechCard) => {
  return (
    <div className="tech-stack-content-card-sm">
      <div className="tech-stack-content-card-sm-title">{props.title}</div>
      <div className="tech-stack-content-card-sm-image-div">
        <img src={props.iconUrl} alt="icon" />
      </div>
      <div className="tech-stack-content-card-sm-description">
        {props.description.map((d, i) => (
          <p key={`teck-card-des-${i}`}>{d}</p>
        ))}
      </div>
    </div>
  );
};
export const TechStack = () => {
  const mainCards: TechCard[] = [
    {
      title: "Unity",
      iconUrl: "/SkillIcons/unity.png",
      description: [
        "Two years full time unity developer.",
        "Gamejams every year.",
      ],
    },
    {
      title: "C#",
      iconUrl: "/SkillIcons/csharp.png",
      description: [
        "Three years of experiences",
        "Strong Object-Oriented Programming Skills",
        "Great design patterns knowledge",
      ],
    },
    {
      title: "C++",
      iconUrl: "/SkillIcons/cpp.png",
      description: [
        "Solid fundation in memory operations.",
        "Two years experience of school projects programming.",
      ],
    },
    {
      title: "Python",
      iconUrl: "/SkillIcons/python.png",
      description: [
        "Great language for all kinds of small tasks!",
        "But I have to say I hate the indentation so much!",
      ],
    },
    {
      title: "ReactJS",
      iconUrl: "/SkillIcons/reactjs.png",
      description: ["This website is made with React!"],
    },
    {
      title: "Git",
      iconUrl: "/SkillIcons/git.png",
      description: [
        "Solid understanding in Git",
        "Familiar with collaboration process",
        "github pr",
      ],
    },
  ];

  const subCards: TechCard[] = [
    {
      title: "AWS",
      iconUrl: "/SkillIcons/aws.png",
      description: [
        "Basic understanding in AWS",
        "This website is powered by EC2",
      ],
    },
    {
      title: "Blender",
      iconUrl: "/SkillIcons/blender.ico",
      description: [
        "Basic knowledge in Blender",
        "Familar with basic shaping tools",
      ],
    },
    {
      title: "Unreal",
      iconUrl: "/SkillIcons/unreal.png",
      description: ["Beginer level in unreal", "Keep learning"],
    },
    {
      title: "NodeJs",
      iconUrl: "/SkillIcons/nodejs.png",
      description: [
        "Basic knowledge in NodeJs",
        "This website use Nodejs as backend",
      ],
    },
    {
      title: "AI/Machine Learning",
      iconUrl: "/SkillIcons/robot.png",
      description: [
        "Solid understanding in rational agent",
        "Familiar with different search algorithms",
      ],
    },
    {
      title: "Jenkins",
      iconUrl: "/SkillIcons/jenkins.png",
      description: ["Basic Jenkins pipeline knowledge on automated tasks"],
    },
    {
      title: "Scss",
      iconUrl: "/SkillIcons/scss.png",
      description: ["Basic Scss knowledge on writing css files"],
    },
    {
      title: "Linux/Unix Environment",
      iconUrl: "/SkillIcons/linux.ico",
      description: ["Familar with Linux command-line tools"],
    },
    {
      title: "Nginx",
      iconUrl: "/SkillIcons/nginx.png",
      description: [
        "Basic knowledge on Nginx configuration",
        "This website is powered by Nginx",
      ],
    },
  ];
  return (
    <section className="tech-stack-section">
      <h2 className="section_heading">Major Tech Stack</h2>
      <div className="tech-stack-content">
        {mainCards.map((e, i) => {
          e["key"] = `tech-card-${i}`;
          return <TechStackCard {...e} />;
        })}
      </div>
      <h2 className="section_heading">Minor Tech Stack</h2>
      <div className="tech-stack-content">
        {subCards.map((e, i) => {
          e["key"] = `tech-sm-card-${i}`;
          return <SmallTechStackCard {...e} />;
        })}
      </div>
    </section>
  );
};
