import { useEffect } from "react";
import ImageSlider from "../ReactComponents/components/ImageSlider";
import { TechStack } from "../ReactComponents/components/TechStack";
import TimelineContainer from "../ReactComponents/components/TimelineContainer";

export default function Home() {
  useEffect(() => {
    document.title = "Portfolio - Jinming Zhang"
  }, []);
  return (
    <div id="home">
      <ImageSlider />
      <TechStack/>
      <TimelineContainer />
    </div>
  );
}
