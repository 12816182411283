import React, { useEffect, useContext, useRef, useState } from "react";
import { globalContext } from "../..";
import Spinner from "./spinner";

export default function ContactForm() {
  // configs
  const context = useContext(globalContext);
  const apiUrlHost = context.serverUrl;
  const apiUrl = encodeURI(`${apiUrlHost}api/send-email`);

  // refs
  const enteredName = useRef<HTMLInputElement>(null);
  const enteredEmail = useRef<HTMLInputElement>(null);
  const enteredMsg = useRef<HTMLTextAreaElement>(null);
  const formDivRef = useRef<HTMLDivElement>(null);

  // states
  const [formState, setFormState] = useState({
    sending: false,
    message: "",
    statusCode: 200,
  });
  const [formSize, setFormSize] = useState<number[]>([]);
  useEffect(() => {
    const updateSize = () => {
      setFormSize([
        formDivRef.current!.clientWidth,
        formDivRef.current!.clientHeight,
      ]);
    };
    window.addEventListener("resize", updateSize);
  }, []);
  // callbacks
  const onSendButtonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    console.log(
      enteredName.current!.value,
      enteredEmail.current!.value,
      enteredMsg.current!.value
    );

    setFormState({ sending: true, message: "", statusCode: 200 });

    const data = {
      name: enteredName.current!.value,
      email: enteredEmail.current!.value,
      message: enteredMsg.current!.value,
    };

    fetch(apiUrl, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      redirect: "follow",
    })
      .then((resp) => {
        resp.text().then((t) => {
          console.log(t);
          const msg = resp.ok ? "Message Sent!" : "Something went wrong...";
          setFormState({
            sending: false,
            message: msg,
            statusCode: resp.status,
          });
        });
      })
      .catch((e) => {
        setFormState({
          sending: false,
          message: "Something went wrong...",
          statusCode: 400,
        });
      });
  };

  // form html
  const form = (
    <form className="contact-form">
      <div className="input-field name-input">
        <label className="input-field-label" htmlFor="">
          Name
        </label>
        <input className="input-field-input" type="text" ref={enteredName} />
      </div>
      <div className="input-field email-input">
        <label className="input-field-label" htmlFor="">
          Email
        </label>
        <input className="input-field-input" type="email" ref={enteredEmail} />
      </div>
      <div className="input-field msg-input">
        <label className="input-field-label" htmlFor="">
          Message
        </label>
        <textarea
          className="input-field-input"
          form="contact-form"
          ref={enteredMsg}
        />
      </div>
      {/* // message */}
      {formState.message === "" ? null : <div>{formState.message}</div>}

      <div className="contact-btns">
        <button
          type="button"
          disabled={formState.sending}
          onClick={onSendButtonClicked}
        >
          Submit
        </button>
        <button type="reset" disabled={formState.sending}>
          Clear
        </button>
      </div>
    </form>
  );
  return (
    <section id="contact-form-sec">
      <div className="mail-icon">
        <i className="fa fa-envelope"></i>
      </div>
      <div
        // className={`form-area ${formState.sending ? "backdrop" : ""}`}
        className={`form-area`}
        ref={formDivRef}
      >
        {form}
        {formState.sending ? (
          <div
            className="form-spinner backdrop"
            style={{
              width: formSize[0],
              height: formSize[1],
            }}
          >
            <Spinner />
          </div>
        ) : null}
      </div>
    </section>
  );
}
